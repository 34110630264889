exports.components = {
  "component---src-getpowermetrics-404-js": () => import("./../../../src/getpowermetrics/404.js" /* webpackChunkName: "component---src-getpowermetrics-404-js" */),
  "component---src-getpowermetrics-about-contact-us-js": () => import("./../../../src/getpowermetrics/about/contact-us.js" /* webpackChunkName: "component---src-getpowermetrics-about-contact-us-js" */),
  "component---src-getpowermetrics-company-about-js": () => import("./../../../src/getpowermetrics/company/about.js" /* webpackChunkName: "component---src-getpowermetrics-company-about-js" */),
  "component---src-getpowermetrics-company-partners-js": () => import("./../../../src/getpowermetrics/company/partners.js" /* webpackChunkName: "component---src-getpowermetrics-company-partners-js" */),
  "component---src-getpowermetrics-company-services-js": () => import("./../../../src/getpowermetrics/company/services.js" /* webpackChunkName: "component---src-getpowermetrics-company-services-js" */),
  "component---src-getpowermetrics-consistency-1-js": () => import("./../../../src/getpowermetrics/consistency/1.js" /* webpackChunkName: "component---src-getpowermetrics-consistency-1-js" */),
  "component---src-getpowermetrics-everybodys-business-1-js": () => import("./../../../src/getpowermetrics/everybodys-business/1.js" /* webpackChunkName: "component---src-getpowermetrics-everybodys-business-1-js" */),
  "component---src-getpowermetrics-index-js": () => import("./../../../src/getpowermetrics/index.js" /* webpackChunkName: "component---src-getpowermetrics-index-js" */),
  "component---src-getpowermetrics-integrations-index-js": () => import("./../../../src/getpowermetrics/integrations/index.js" /* webpackChunkName: "component---src-getpowermetrics-integrations-index-js" */),
  "component---src-getpowermetrics-integrations-semantic-layer-dbt-js": () => import("./../../../src/getpowermetrics/integrations/semantic-layer/dbt.js" /* webpackChunkName: "component---src-getpowermetrics-integrations-semantic-layer-dbt-js" */),
  "component---src-getpowermetrics-integrations-semantic-layer-index-js": () => import("./../../../src/getpowermetrics/integrations/semantic-layer/index.js" /* webpackChunkName: "component---src-getpowermetrics-integrations-semantic-layer-index-js" */),
  "component---src-getpowermetrics-pricing-js": () => import("./../../../src/getpowermetrics/pricing.js" /* webpackChunkName: "component---src-getpowermetrics-pricing-js" */),
  "component---src-getpowermetrics-whats-new-js": () => import("./../../../src/getpowermetrics/whats-new.js" /* webpackChunkName: "component---src-getpowermetrics-whats-new-js" */)
}

