const theme = {
  font: {
    primary: `'Open Sans', '-apple-system', 'BlinkMacSystemFont', 'San Francisco', 'Helvetica Neue', 'Helvetica', 'Ubuntu', 'Roboto', 'Noto', 'Segoe UI', 'Arial', sans-serif`,
  },
  font_size: {
    xxxsmall: "font-size: 12px;",
    xxsmall: "font-size: 14px;",
    xsmall: "font-size: 16px;",
    small: "font-size: 17px;",
    regular: "font-size: 22px; line-height: 30px;",
    large: "font-size: 28px; line-height: 30px;",
    larger: "font-size: 40px; line-height: 50px;",
    xlarge: "font-size: 48px; line-height: 48px;",
  },
  defaults: {
    fontSize: {
      p: "1.25rem",
    },
  },

  card: {
    header: {
      font: "font-size: 24px; line-height: 28px; font-weight: 700",
    },
    subtitle: {
      font: "font-size: 16px; line-height: 24px; font-weight: 400",
    },
  },
  notice: {
    font: "font-size: 9px; font-weight: bold;",
  },

  color: {
    white: "#ffffff",
    greyWhite: "#dee2ed",
    greyVeryLight: "#f5f5f5",
    greyLight: "#e6e6e8",
    grey: "#3d455c", //#b2b2b2
    greyLightText: "#aaaaaa",
    greyText: "#333333",
    greyDarkText: "#222222",
    greyDark: "#464648",
    greyVeryDark: "#2a2a2a",
    black: "#000000",
    red: "#e14d57",
    indigo100: "#EDF3FD",
    indigo200: "#B0B8FC",
    indigo300: "#707EFA",
    indigo400: "#4B57C5",
    indigo500: "#343C8D",
    indigo600: "#21264F",
    indigo700: "#0F1124",

    pmGradient100: "#655CFF",
    pmGradient200: "#955CFF",
    pmGradient300: "#A96DFC",
    pmGradient400: "#E09BF5",
    pmGradient500: "#CE74E7",
    pmGradient550: "#CD5CEC",
    pmGradient600: "#B742D8",

    klipsGradient100: "#F2FAE0",
    klipsGradient150: "#B7D96C",
    klipsGradient200: "#C8EBDE",
    klipsGradient250: "#7ACCAE",
    klipsGradient300: "#6ECCDB",
    klipsGradient400: "#49C0DA",
    klipsGradient500: "#17A6D0",
    klipsGradient600: "#1382AB",
    klipsGradient700: "#015D80",

    neutralGradient100: "#5ED4F6",
    neutralGradient200: "#6BACF4",
    neutralGradient300: "#7987F3",
    neutralGradient400: "#836BF2",
    neutralGradient500: "#9538F0",
    neutralGradient600: "#A60AEF",
    neutralGradient700: "#8B00CC",

    red100: "#FAE5EF",
    red200: "#FDA1B9",
    red300: "#FB6987",
    red400: "#E41B50",
    red500: "#B8143E",
    red600: "#730D23",

    orange100: "#FFF5E0",
    orange200: "#FFB85C",
    orange300: "#F38338",
    orange400: "#D24414",
    orange500: "#AC2E1F",
    orange600: "#741E1F",

    green100: "#E8FDE2",
    green200: "#75E1B2",
    green300: "#34AD78",
    green400: "#00805D",
    green500: "#014C33",
    green600: "#002E1F",

    blue100: "#E0F8FF",
    blue200: "#46B0F0",
    blue300: "#5DD5F6",
    blue400: "#18AED8",
    blue500: "#1278A1",
    blue600: "#013950",
    blue700: "#012330",

    navLinkHover: "#f3f5fc",
  },
  screen: {
    xs: "575px",
    sm: "767px",
    md: "991px",
    lg: "1199px",
  },
  breakpoints: [
    ["xs", 376],
    ["sm", 768],
    ["md", 992],
    ["lg", 1200],
  ],
  cell: {
    cellAmount: 40,
    cellSize: "calc(100vw / 40)",
  },
  border: {
    radius: {
      small: "5px",
      medium: "10px",
      large: "1rem",
    },
  },
  shadow: {
    default:
      "0 15px 20px -10px rgb(32 51 70 / 30%), 0 0px 30px 0px rgb(32 51 70 / 20%)",
    light:
      "0 15px 20px -10px rgb(32 51 70 / 15%), 0 0px 30px 0px rgb(32 51 70 / 5%)",
    fancy:
      "0px 44px 44px rgb(33 38 79 / 20%), 0px 34px 34px rgb(33 38 79 / 20%), 0px 24px 24px rgb(52 60 141 / 20%)",
    glassy: "0 4px 30px rgba(0, 0, 0, 0.1)",
    dramatic:
      "0px 0px 10px rgba(165, 171, 192, 0.3), 0px 2px 2px rgba(165, 171, 192, 0.6), 0px 4px 4px rgba(165, 171, 192, 0.6), 0px 16px 16px rgba(165, 171, 192, 0.6), 0px 32px 32px rgba(165, 171, 192, 0.6)",
    hover:
      "30px 34px 64px rgba(0, 0, 0, 0.09), 20px 20px 34px rgba(0, 0, 0, 0.05), 10px 14px 15px rgba(0, 0, 0, 0.05), 0px 4px 35px rgba(0, 0, 0, 0.05)",
    subtle: "0px 0px 30px rgba(0, 0, 0, 0.2)",
    soft: "0px 4px 32px rgb(0 0 0 / 15%)",
    twentyfour: "0px 32px 32px 0px rgba(0, 0, 0, 0.25)",
  },
  filter: {
    shadow: {
      default:
        "drop-shadow(0px 0px 10px rgba(165, 171, 192, 0.3)) drop-shadow(0px 2px 2px rgba(165, 171, 192, 0.6))",
      dramatic:
        "drop-shadow(0px 0px 10px rgba(165, 171, 192, 0.3)) drop-shadow(0px 2px 2px rgba(165, 171, 192, 0.6)) drop-shadow(0px 4px 4px rgba(165, 171, 192, 0.6)) drop-shadow(0px 16px 16px rgba(165, 171, 192, 0.6)) drop-shadow(0px 32px 32px rgba(165, 171, 192, 0.6))",
      dramaticLg:
        "drop-shadow(0px 0px 10px rgba(165, 171, 192, 0.3)) drop-shadow(0px 2px 2px rgba(165, 171, 192, 0.6)) drop-shadow(0px 8px 8px rgba(165, 171, 192, 0.6)) drop-shadow(0px 32px 32px rgba(165, 171, 192, 0.6)) drop-shadow(0px 48px 48px rgba(165, 171, 192, 0.6))",
    },
  },
  transition: {
    default: "all .2s ease",
  },
  gradient: {
    purplePink: "#9886FF 0%, #FF9FBD 100%",
    deepPurplePink: "#7B6CCC 0%, #BC6894 100%",
    klips: "#18AED8 0%, #1278A1 100%",
    powerMetricsButton:
      "linear-gradient(90deg, rgb(99, 91, 255) 0%, rgb(153, 91, 255) 100%)",
    powerMetricsButtonAlt:
      "linear-gradient(90deg, rgb(221, 240, 253) -20%, rgb(174, 221, 250) 0%, rgb(189, 144, 229) 50%, rgb(240, 149, 145) 100%)",
    powerMetricsLightButton:
      "linear-gradient(105deg, #FFF5E0 -35%, #B0B8FC 105%)",
  },
}

export default theme
